new Swiper('.image-slider__swiper', {
    loop: true,
    slidesPerView: 1,
    spaceBetween: 40,
    navigation: {
        nextEl: '.image-slider__next',
        prevEl: '.image-slider__prev' +
            '',
    },
    breakpoints: {
        // when window width is <= 999px
        1000: {
            slidesPerView: 2,
        }
    }
});