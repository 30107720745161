function tabs() {
    let btn = document.getElementsByClassName('three-tabs__btn');
    let content = document.getElementsByClassName('three-tabs__content');

    if ( typeof(btn[0]) != 'undefined' && btn[0] != null && typeof(content[0]) != 'undefined' && content[0] != null) {
        content[0].classList.add('three-tabs__content--active');
        btn[0].classList.add('three-tabs__btn--active');

        for ( let i = 0; i < btn.length; i++ ) {
            btn[i].addEventListener( 'click', function(event) {
                let activeTab = event.currentTarget.dataset.tab;
                let activeContent = document.getElementsByClassName( 'three-tabs__content--active' );
                let activeBtn = document.getElementsByClassName( 'three-tabs__btn--active' );

                if( activeContent.length ) {
                    activeContent[0].classList.remove('three-tabs__content--active');
                    activeBtn[0].classList.remove('three-tabs__btn--active');
                }

                for ( let o = 0; o < content.length; o++ ) {
                    if( activeTab === content[o].dataset.tab ) {
                        content[o].classList.add('three-tabs__content--active');
                        btn[o].classList.add('three-tabs__btn--active');
                    }
                }
            });
        }
    }
}

tabs();