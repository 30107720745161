let sectionField = $('.product-list__materials');
let enterField = $('.product-list__materials .product-table .product-table__row-item');
let body = $('body');

if ( typeof(sectionField[0]) != 'undefined' && sectionField[0] != null && window.innerWidth > 768 ) {

    body[0].classList.add('material-hover-active');

    /*
    set all variables
    */
    let mouseFollow = $('.product-list__materials .mouse-hover');
    let mouseHoverSrc = $('.product-list__materials .mouse-hover__image img');

    document.body.addEventListener('mousemove', function(e) {

        mouseFollow[0].style.left = ( e.clientX + 24 ) + "px";
        mouseFollow[0].style.top = ( e.clientY + window.pageYOffset ) + "px";

    });

    for (let x = 0; x < enterField.length; x++) {
        enterField[x].addEventListener('mouseenter', function() {
            let imageSrc = this.querySelector('.product-table__product').getAttribute('data-image-src');
            mouseHoverSrc.attr('src', imageSrc);
            mouseFollow[0].classList.add('mouse-hover__active');
        });

        enterField[x].addEventListener('mouseleave', function() {
            mouseFollow[0].classList.remove('mouse-hover__active');
        });
    }

}