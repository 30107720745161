import mixitup from 'mixitup';
import mixitupMultifilter from '../../js/important/mixitup-multifilter.min';

let containerEl = document.querySelector("#mixitup_container");

if ( typeof(containerEl) != 'undefined' && containerEl != null ) {

    mixitup.use(mixitupMultifilter);

    let filterSelectZero = document.querySelector('select#FilterSelect_0');
    let filterSelectOne = document.querySelector('select#FilterSelect_1');

    let courseFilter = mixitup(containerEl, {
        animation: {
            enable: false
        },
        multifilter: {
            enable: true
        }
    });

    filterSelectZero.addEventListener('change', function() {
        courseFilter.filter(this.value);
    });

    filterSelectOne.addEventListener('change', function() {
        courseFilter.filter(this.value);
    });

}
